import React, {Dispatch, SetStateAction, useState} from "react";
import {CustomInput, PasswordHiddenIcon, PasswordVisibleIcon} from "../../../helpers/common";
import {setCookie} from "typescript-cookie";
import {AuthService} from "../../../services/AuthService";
import {access_token_name, refresh_token_name} from "../../../http";
import jwtDecode from "jwt-decode";

const authStateInitialValues = {
    values:{
        email: '',
        password: '',
    },
    validation:{
        emailCheck: false,
        emailHelperText: '',
        passwordCheck: false,
        passwordHelperText: '',
        passwordVisible: false,
        requestIsSent: false,
    }
}

function AuthModal({setAuthSteps}: {setAuthSteps: Dispatch<SetStateAction<string>>}){
    const [authState, setAuthState] = useState<any>(authStateInitialValues)

    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        setAuthState({
            ...authState,
            validation: {
                ...authState.validation,
                requestIsSent: true,
            }
        })
        AuthService.GetToken(authState.values.email, authState.values.password).then((res)=>{
            // Get the current time in seconds
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);

            const accessDecode: any = jwtDecode(res.data.access)
            const refreshDecode: any = jwtDecode(res.data.refresh)

            const accessExpirationInSeconds = accessDecode.exp;
            const refreshExpirationInSeconds = refreshDecode.exp;

            // Calculate the difference in seconds
            const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;
            const refreshDifferenceInSeconds = refreshExpirationInSeconds - currentTimeInSeconds;

            // Convert the difference in seconds to days
            const accessDifferenceInDays = Math.ceil(accessDifferenceInSeconds / (60 * 60 * 24));
            const refreshDifferenceInDays = Math.ceil(refreshDifferenceInSeconds / (60 * 60 * 24));
            setCookie(access_token_name, res.data.access, {expires: accessDifferenceInDays})
            setCookie(refresh_token_name, res.data.refresh, {expires: refreshDifferenceInDays})
            setTimeout(()=>{
                window.location.reload()
            },1000)
        }).catch((err)=>{
            if(err.response.status === 401){
                setAuthState({
                    ...authState,
                    validation: {
                        ...authState.validation,
                        emailCheck: true,
                        emailHelperText: err.response.data.detail,
                        passwordCheck: true,
                        passwordHelperText: err.response.data.detail,
                        requestIsSent: false,
                    }
                })
            }
            if(err.response.status === 404){
                setAuthState({
                    ...authState,
                    validation: {
                        ...authState.validation,
                        emailCheck: true,
                        emailHelperText: err.response.statusText,
                        passwordCheck: true,
                        passwordHelperText: err.response.statusText,
                        requestIsSent: false,
                    }
                })
            }
        })
    }

    return (
        <div className='flex justify-center items-center w-full min-h-screen h-screen'>
            <form onSubmit={handleSubmit} className='w-full h-full flex flex-col justify-start items-center p-6 pt-24 relative bg-white rounded-10px shadow-default w-full'>
                <h1 className="auth__title">Авторизация</h1>
                <CustomInput
                    className='text-input'
                    type='email'
                    placeholder='Email'
                    required={true}
                    label='Email'
                    value={authState.values.email}
                    onChange={(event)=>{
                        setAuthState({
                            ...authState,
                            values:{
                                ...authState.values,
                                email: event.target.value,
                            }
                        })
                    }}
                    helperText={authState.values.emailHelperText}
                    error={authState.values.emailCheck}
                />
                <CustomInput
                    className='text-input text-input_with-props'
                    type={authState.validation.passwordVisible ? 'text' : 'password'}
                    placeholder='Пароль'
                    label='Пароль'
                    value={authState.values.password}
                    helperText={authState.validation.passwordHelperText}
                    required={true}
                    onChange={(event)=>{
                        setAuthState({
                            ...authState,
                            values:{
                                ...authState.values,
                                password: event.target.value,
                            }
                        })
                    }}
                    error={authState.validation.passwordCheck}
                    inputProps={
                        <div className='text-input_icon_button' onClick={()=>{
                            setAuthState({
                                ...authState,
                                validation:{
                                    ...authState.validation,
                                    passwordVisible: !authState.validation.passwordVisible,
                                }
                            })
                        }}>
                            {authState.validation.passwordVisible ? <PasswordHiddenIcon/> : <PasswordVisibleIcon/>}
                        </div>
                    }
                />
                <div className='w-full flex justify-end'>
                    <p className='text-xs not-italic font-normal hover:underline cursor-pointer'
                       onClick={()=>{setAuthSteps('recover')}}
                    >
                        Забыли пароль?
                    </p>
                </div>
                <button disabled={authState.validation.requestIsSent} type='submit' className='submit-button_orange h-12 w-full mt-4'>
                    {authState.validation.requestIsSent
                        ?<div className="loader"></div>
                        :'Войти'
                    }
                </button>
                <div className='w-full flex justify-center items-center gap-3 py-5'>
                    <span className='text-xs not-italic font-normal text-dark-grey'>
                        Нет аккаунта?
                    </span>
                    <p className='text-xs not-italic font-normal hover:underline cursor-pointer'
                       onClick={()=>{setAuthSteps('register')}}
                    >
                        Зарегистрируйтесь
                    </p>
                </div>
            </form>
        </div>
    );
}

export default AuthModal;