import React, {useEffect, useState} from "react";
import {OrdersService} from "../../services/OrdersService";
import {Link, useParams} from "react-router-dom";
import moment from "moment/moment";
import {CustomInput, CustomPageSizeInput, FilterSearchIcon, NoDataIcon} from "../../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../../helpers/helpers";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const tableFilter = {
    search: '',
    start_date: '',
    status__status: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}
export default function OrdersM() {
    const {status} = useParams()
    const [openFilter, setOpenFilter] = useState(false)
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: {
            ...tableFilter,
            status__status: status === 'showAll' ? '' : status,
        },
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns: [],
    })

    const ordersList = OrdersService.GetOrders(table.filter)

    useEffect(() => {
        if (ordersList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                loading: true,
            }))
        } else if (ordersList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                error: true,
                message: ordersList.error?.message,
                loading: false,
            }))
        } else {
            const data = ordersList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    }, [ordersList.loading, ordersList.error])

    return (
        <div className='defaultWrapper'>
            <div className={`blurBox ${openFilter ? 'blurBox__opened' : ''} z-99`} onClick={()=>{setOpenFilter(false)}}></div>
            <div className={`mobileFilter ${openFilter ? 'mobileFilter__opened' : ''}`}>
                <div className='w-full flex justify-between items-center pb-2 border-b border-b-[#2B5EE8] z-999'>
                    <h3 className='text-gray-900 text-2xl not-italic font-bold'>Посылки</h3>
                    <button className='mobileFilterButton' onClick={()=>{
                        setOpenFilter(!openFilter)
                    }}>
                        {openFilter
                            ? <ArrowDropUpIcon/>
                            : <ArrowDropDownIcon/>
                        }
                    </button>
                </div>
                <div className='w-full'>
                    <div className='w-full flex-col'>
                        <div className='flex w-full flex-row justify-start items-center gap-5'>
                            <CustomInput
                                className='text-input'
                                type='date'
                                placeholder='Начало даты'
                                value={table.filter.start_date}
                                onChange={(event) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            start_date: event.target.value,
                                            page: 1,
                                        }
                                    })
                                }}
                            />
                            <CustomInput
                                className='text-input'
                                type='date'
                                placeholder='Конец даты'
                                value={table.filter.end_date}
                                onChange={(event) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            end_date: event.target.value,
                                            page: 1,
                                        }
                                    })
                                }}
                            />
                        </div>
                        <CustomInput
                            className='text-input'
                            type='text'
                            placeholder='Поиск'
                            value={table.filter.search}
                            onChange={(event) => {
                                setTable({
                                    ...table,
                                    selectedRows:[],
                                    filter: {
                                        ...table.filter,
                                        search: event.target.value,
                                        page: 1,
                                    }
                                })
                            }}
                            inputProps={
                                <div className='text-input_icon_button'>
                                    <FilterSearchIcon/>
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>

            <div className='w-full flex flex-col gap-3'>
                {table.loading
                    ? table.rows.length === 0
                        ?
                        <div className='w-full flex  flex-col justify-center items-center py-32 gap-5'>
                            <div className='loader'></div>
                            <p className='text-gray-500 text-xs not-italic font-medium'>
                                Загрузка
                            </p>
                        </div>
                        :
                        <>
                            {table.rows.map((order: any, index: number)=>
                                <Link to={`/ordersDetail/${order.id}`} key={index}
                                      className='w-full p-5 flex flex-col gap-2.5 rounded-xl shadow-default bg-white'
                                >
                                    <div className='w-full flex justify-between items-start gap-2.5 pb-2.5 border-b border-b-yellow-600'>
                                        <div className='flex justify-start items-start gap-2.5'>
                                            <img src={order.type_of_transport.icon} alt="icon"/>
                                            <div className='flex flex-col justify-start items-start gap-2'>
                                                <p className='text-gray-900 text-sm not-italic font-bold overflow-ellipsis'>
                                                    {order.title}
                                                </p>
                                                <span className='text-gray-900 text-xs not-italic font-normal'>
                                                            {order.tracking_number}
                                                        </span>
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{__html: order.status.svg_script}}/>
                                    </div>
                                    <div className='w-full flex justify-between items-center'>
                                        <span>{order.sending_country.name}</span>
                                        <span>{moment(order.created_at).format('YYYY.MM.DD')}</span>
                                    </div>
                                </Link>
                            )}
                            <div className="bg-white flex justify-between items-center p-2.5 rounded-xl">
                                <div className='w-full flex justify-between items-center gap-4'>
                                    <Pagination
                                        size='small'
                                        count={table.filter.total_pages}
                                        page={table.filter.page}
                                        onChange={(event, value) => {
                                            setTable({
                                                ...table,
                                                filter: {
                                                    ...table.filter,
                                                    page: value,
                                                }
                                            })
                                        }}
                                    />
                                    <CustomPageSizeInput
                                        mobile={true}
                                        value={table.filter.size}
                                        onChange={(e) => {
                                            setTable({
                                                ...table,
                                                filter: {
                                                    ...table.filter,
                                                    page: 1,
                                                    size: CheckForPositiveNumbers(e.target.value),
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    : table.error
                        ?
                        <div className='w-full flex  flex-col justify-center items-center py-32 gap-5'>
                            <NoDataIcon/>
                            <p className='text-gray-500 text-xs not-italic font-medium'>
                                {table.message}
                            </p>
                        </div>
                        : table.rows.length === 0
                            ?
                            <div className='w-full flex  flex-col justify-center items-center py-32 gap-5'>
                                <NoDataIcon/>
                                <p className='text-gray-500 text-xs not-italic font-medium'>
                                    Нет данных
                                </p>
                            </div>
                            :
                            <>
                                {table.rows.map((order: any, index: number)=>
                                    <Link to={`/ordersDetail/${order.id}`} key={index}
                                          className='w-full p-5 flex flex-col gap-2.5 rounded-xl shadow-default bg-white'
                                    >
                                        <div className='w-full flex justify-between items-start gap-2.5 pb-2.5 border-b border-b-yellow-600'>
                                            <div className='flex justify-start items-start gap-2.5'>
                                                <img src={order.type_of_transport.icon} alt="icon"/>
                                                <div className='flex flex-col justify-start items-start gap-2'>
                                                    <p className='text-gray-900 text-sm not-italic font-bold overflow-ellipsis'>
                                                        {order.title}
                                                    </p>
                                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                                            {order.tracking_number}
                                                        </span>
                                                </div>
                                            </div>
                                            <div dangerouslySetInnerHTML={{__html: order.status.svg_script}}/>
                                        </div>
                                        <div className='w-full flex justify-between items-center'>
                                            <span>{order.sending_country.name}</span>
                                            <span>{moment(order.created_at).format('YYYY.MM.DD')}</span>
                                        </div>
                                    </Link>
                                )}
                                <div className="bg-white flex justify-between items-center p-2.5 rounded-xl">
                                    <div className='w-full flex justify-between items-center gap-4'>
                                        <Pagination
                                            size='small'
                                            count={table.filter.total_pages}
                                            page={table.filter.page}
                                            onChange={(event, value) => {
                                                setTable({
                                                    ...table,
                                                    filter: {
                                                        ...table.filter,
                                                        page: value,
                                                    }
                                                })
                                            }}
                                        />
                                        <CustomPageSizeInput
                                            mobile={true}
                                            value={table.filter.size}
                                            onChange={(e) => {
                                                setTable({
                                                    ...table,
                                                    filter: {
                                                        ...table.filter,
                                                        page: 1,
                                                        size: CheckForPositiveNumbers(e.target.value),
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                }
            </div>
        </div>
    )
}