import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput, CustomPageSizeInput,
    CustomTable,
} from "../../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers} from "../../helpers/helpers";
import {NotificationsService} from "../../services/NotificationsService";
import moment from "moment/moment";
import parse from 'html-react-parser'

const tableFilter = {
    search: '',
    start_date: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}
const notificationInfoInitialState: any = {
    title: '',
    message: '',
    created_at: '',
}

const notificationValidationInitialState = {
    requestIsSent: false,
    modal_opened: false,
    type: '',
}

export default function Notifications() {
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                { field: 'id', headerName: 'ID', width: '100px', hide: true},
                { field: 'title', headerName: 'Уведомление', width: '100%', maxWidth: '500px', renderCell: (params: any) => (
                        <p className='notificationsRow'>
                            <p style={params.read ? {color:'rgba(87,88,90,0.8)'} : {color: '#000032'}}>{params.notification.title}</p>
                            <p >{parse(params.notification.message)}</p>
                        </p>
                    )},
                { field: 'created_at', headerName: 'Дата', width: '110px', renderCell: (params: any) => (
                        moment(params.notification.created_at).format('YYYY-MM-DD hh:mm')
                    )},
            ]
    })

    const [notificationInfo, setNotificationInfo] = useState<any>(notificationInfoInitialState)

    const [notificationValidation, setNotificationValidation] = useState<any>(notificationValidationInitialState)

    const notificationList = NotificationsService.GetNotificationsList(table.filter)

    const setNotificationIntoModal = (notificationInfoProps: any, type: string)=>{
        setNotificationInfo({
            ...notificationInfo,
            title: notificationInfoProps.notification.title,
            message: notificationInfoProps.notification.message,
            created_at: notificationInfoProps.notification.created_at,
        })
        NotificationsService.SetNotificationRead(notificationInfoProps.id)
        setNotificationValidation({...notificationValidation, modal_opened: true, type: type})
        setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
    }

    const submitNotificationForm = ()=>{
        setNotificationValidation({
            ...notificationValidation,
            requestIsSent: true,
        })
        if(notificationValidation.type === 'view'){
            setNotificationValidation(notificationValidationInitialState)
            setNotificationInfo(notificationInfoInitialState)
            setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
        }
        if(notificationValidation.type === 'delete'){
            NotificationsService.DeleteSelectedNotifications(table.selectedRows).then(()=>{
                setNotificationValidation(notificationValidationInitialState)
                setNotificationInfo(notificationInfoInitialState)
                setTable({...table,filter: {...table.filter, updateTable: !table.filter.updateTable}})
            })
        }
    }

    useEffect(()=>{
        if(notificationList.loading){
            setTable((prevState: any)=>({
                ...prevState,
                loading: true,
            }))
        }
        else if(notificationList.error){
            setTable((prevState: any)=>({
                ...prevState,
                error: true,
                message: notificationList.error?.message,
                loading: false,
            }))
        }
        else{
            const data = notificationList.result?.data
            setTable((prevState: any)=>({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    },[notificationList.loading, notificationList.error])
    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Уведомления</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Начало даты'
                        value={table.filter.start_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    start_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Конец даты'
                        value={table.filter.end_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    end_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                </div>
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={true}
                    selectedRowsId={table.selectedRows}
                    onSelectionModelChange={(selectionModel)=>setTable({
                        ...table,
                        selectedRows: selectionModel,
                    })}
                    onSelectAll={(params)=>params.user !== null}
                    isRowSelectDisabled={(params)=> params.user === null}
                    multipleSelect={table.filter.search !== ''}
                    onRowDoubleClick={(params)=> setNotificationIntoModal(params, 'view')}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event,value)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e)=>{
                                        setTable({
                                            ...table,
                                            filter:{
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <button className='submit-button_orange flex-shrink-0 submit-button_blue h-10'
                                    disabled={table.selectedRows.length === 0}
                                    onClick={()=>{
                                        setNotificationValidation({...notificationValidation, modal_opened: true, type: 'delete'})
                                    }}
                            >
                                Удалить
                            </button>
                        </div>
                    }
                />
            </div>

            <CustomForm
                open={notificationValidation.modal_opened}
                title={notificationValidation.type === 'delete' ? `Удалить выбранные уведомления?` : 'Просмотр уведомления'}
                style={{maxWidth: '844px', width:'100%'}}
                buttonText={notificationValidation.type === 'delete' ? `Удалить` : 'Закрыть'}
                cancelButton={notificationValidation.type !== 'view'}
                cancelButtonText='Отмена'
                onClose={()=>{
                    setNotificationValidation(notificationValidationInitialState)
                    setNotificationInfo(notificationInfoInitialState)
                }}
                onSubmit={()=>{
                    submitNotificationForm()
                }}
                disabled={notificationValidation.requestIsSent}
                requestIsSent={notificationValidation.requestIsSent}
                children={
                    <>
                        {notificationValidation.type === 'view' &&
                            <div className='notificationsRow w-full flex flex-col gap-5'>
                                <div className='w-full flex justify-between items-start'>
                                    <h3 className='text-gray-900 text-2xl not-italic font-medium'>
                                        {notificationInfo.title}
                                    </h3>
                                    <span>{moment(notificationInfo.created_at).format('YYYY-MM-DD hh:mm')}</span>
                                </div>
                                <div className='w-full break-words'>
                                    {parse(notificationInfo.message)}
                                </div>
                            </div>
                        }
                    </>
                }
            />
        </div>
    )
}