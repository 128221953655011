import {$axios} from "../http";
import {useAsync} from "react-async-hook";
import {TariffI, TariffUpdateI} from "../models/TariffI";
import {CreateCleanSearchParams} from "../helpers/helpers";

export const AddressesService = {
    GetAddressList(searchParams: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/tariff/' + CreateCleanSearchParams(searchParams))
        }, [CreateCleanSearchParams(searchParams)])
    },
    GetAddress(id: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/tariff/${id}/`)
        }, [id])
    },
}