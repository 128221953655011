import React, {useEffect, useState} from "react";
import {
    CustomInput, CustomSelect, FileUploadIcon,
    RollBackIcon,
} from "../../helpers/common";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {PersonalService} from "../../services/PersonalService";
import {CityService} from "../../services/CityService";
import {RegionService} from "../../services/RegionService";
import {MyTimer, ValidatePhoneNumber} from "../../helpers/helpers";
import {AuthService} from "../../services/AuthService";
import {UserI} from "../../models/UserI";

const personalStateInitialState = {
    values: {
        full_name: '',
        phone: '',
        email: '',
        address: '',
        region: '',
        city: '',
        password: '',
        confirm_password: '',
        otp: '',
        passport_front_side: new File([""], ""),
        passport_back_side: new File([""], ""),
        passport_frontCheck: '',
        passport_backCheck: '',
    },
    validation:{
        emailCheck: false,
        emailHelperText: '',
        phoneCheck: false,
        phoneHelperText: '',
        passwordVisible: false,
        otpCheck: false,
        otpHelperText: '',
        sendCode: true,
        requestIsSent: false,
    }
}
export default function PersonalEdit() {
    const navigate = useNavigate();
    const user = useSelector((state: any) => state.user)
    const dispatch = useDispatch()
    const setUser = ({...data}:UserI ) =>{
        dispatch({
            type:"UPDATE_DATA",
            payload:{
                ...data,
            }
        })
    }

    const [personalState, setPersonalState] = useState(personalStateInitialState)

    const personalInfo = PersonalService.GetPersonalInfo()
    useEffect(()=>{
        if(!personalInfo.loading && !personalInfo.error){
            setPersonalState({
                ...personalState,
                values:{
                    ...personalState.values,
                    ...personalInfo.result?.data,
                    passport_frontCheck: personalInfo.result?.data.passport_front_side,
                    passport_backCheck: personalInfo.result?.data.passport_back_side,
                    passport_front_side: new File([""], ""),
                    passport_back_side: new File([""], ""),
                    city: personalInfo.result?.data.city ? personalInfo.result?.data.city.id : null,
                    region: personalInfo.result?.data.region.id,
                }
            })
        }
    },[personalInfo.loading, personalInfo.error])

    const cities = CityService.GetCitiesList()
    const regions = RegionService.GetRegionsList()


    const handlePassportChange = (event: React.ChangeEvent<HTMLInputElement>, side: string ) =>{
        const files = event.target.files
        if(files !== null && files.length > 0){
            let fileReader = new FileReader()

            fileReader.onload = (event) =>{
                if(side === 'front' && event.target !== null && typeof event.target.result === 'string'){
                    setPersonalState({
                        ...personalState,
                        values:{
                            ...personalState.values,
                            passport_front_side: files[0],
                            passport_frontCheck: `${event.target.result}`
                        }
                    })
                }
                if(side === 'back' && event.target !== null && typeof event.target.result === 'string'){
                    setPersonalState({
                        ...personalState,
                        values:{
                            ...personalState.values,
                            passport_back_side: files[0],
                            passport_backCheck: `${event.target.result}`
                        }
                    })
                }
            }

            fileReader.readAsDataURL(files[0])
        }
    }

    const submitPersonalForm = (event: React.FormEvent)=>{
        event.preventDefault()
        setPersonalState({
            ...personalState,
            validation: {
                ...personalState.validation,
                requestIsSent: true,
            },
        })
        const userInfo = {
            ...user,
            ...personalState.values,
        }
        delete userInfo.passport_backCheck
        delete userInfo.passport_frontCheck
        delete userInfo.old_password
        delete userInfo.password
        delete userInfo.confirm_password

        PersonalService.UpdatePersonalInfo(userInfo).then((res)=>{
            navigate(-1)
            setTimeout(()=>{
                window.location.reload()
            },1000)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const time = new Date();
    const currentTimeLeftForCode = MyTimer(time)
    const handleResendCode = () =>{
        setPersonalState({
            ...personalState,
            validation:{
                ...personalState.validation,
                sendCode: true,
                otpHelperText: 'Код отправлен вам на почту'
            },
        })
        const time = new Date();
        time.setSeconds(time.getSeconds() + 59);
        currentTimeLeftForCode.restart(time)
        AuthService.GetOtp(personalState.values.email).then(()=>{
        }).catch(()=>{
            setPersonalState({
                ...personalState,
                validation:{
                    ...personalState.validation,
                    sendCode: false,
                    otpHelperText: 'Ошибка при отправке кода',
                    otpCheck: true,
                },
            })
        })
    }
    useEffect(()=>{
        if(currentTimeLeftForCode.seconds === 0){
            setPersonalState({
                ...personalState,
                validation:{
                    ...personalState.validation, sendCode: false
                },
            })
        }
    }, [currentTimeLeftForCode.seconds])
    return(
        <div className='componentMainWrapper'>
            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Редактировать личную страницу</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-2'>
                    <div className='page__rollBack-icon' onClick={()=>{navigate(-1)}}>
                        <div>
                            <RollBackIcon/>
                        </div>
                        <p>Назад</p>
                    </div>
                </div>
            </div>

            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl p-8 mb-12'>
                <form onSubmit={submitPersonalForm} className='w-full grid grid-cols-3 gap-7'>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='ФИО'
                        required={true}
                        label='ФИО'
                        value={personalState.values.full_name}
                        onChange={(event)=>{
                            setPersonalState({
                                ...personalState,
                                values:{
                                    ...personalState.values,
                                    full_name: event.target.value,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Адрес'
                        required={true}
                        label='Адрес'
                        value={personalState.values.address}
                        onChange={(event)=>{
                            setPersonalState({
                                ...personalState,
                                values:{
                                    ...personalState.values,
                                    address: event.target.value,
                                }
                            })
                        }}
                    />
                    <CustomSelect
                        className='default-select'
                        placeholder='Область:'
                        label='Область:'
                        required={true}
                        value={personalState.values.region}
                        onChange={(event)=>{
                            setPersonalState({
                                ...personalState,
                                values:{
                                    ...personalState.values,
                                    region: event.target.value,
                                }
                            })
                        }}
                    >
                        <option value=""></option>
                        {!regions.loading && !regions.error && regions.result?.data.map((region: any, i:number)=>(
                            <option key={i} value={region.id}>{region.name}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        className='default-select'
                        placeholder='Город:'
                        label='Город:'
                        required={true}
                        value={personalState.values.city}
                        onChange={(event)=>{
                            setPersonalState({
                                ...personalState,
                                values:{
                                    ...personalState.values,
                                    city: event.target.value,
                                }
                            })
                        }}
                    >
                        <option value=""></option>
                        {!cities.loading && !cities.error && cities.result?.data.map((city: any, i:number)=>(
                            <option key={i} value={city.id}>{city.name}</option>
                        ))}
                    </CustomSelect>
                    <CustomInput
                        className='text-input'
                        type='text'
                        placeholder='Номер телефона'
                        required={true}
                        label='Номер телефона'
                        value={personalState.values.phone}
                        onChange={(event)=>{
                            setPersonalState({
                                ...personalState,
                                values:{
                                    ...personalState.values,
                                    phone: ValidatePhoneNumber(event.target.value),
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='text-input'
                        type='email'
                        placeholder='Email'
                        required={true}
                        label='Email'
                        value={personalState.values.email}
                        onChange={(event)=>{
                            setPersonalState({
                                ...personalState,
                                values:{
                                    ...personalState.values,
                                    email: event.target.value,
                                }
                            })
                        }}
                    />
                    {personalState.values.email !== user.email &&
                        <CustomInput
                            className='text-input'
                            type='text'
                            placeholder='Код'
                            required={true}
                            label=''
                            value={personalState.values.otp}
                            onChange={(event)=>{setPersonalState({
                                ...personalState,
                                values:{
                                    ...personalState.values,
                                    otp: event.target.value,
                                },
                            })}}
                            helperText={personalState.validation.otpHelperText}
                            error={personalState.validation.otpCheck}
                            inputProps={
                                <button type='button' className='otp_icon_button'>
                                    {!personalState.validation.sendCode
                                        ? <p onClick={()=>handleResendCode()}>Отправить код</p>
                                        : <p>0:{currentTimeLeftForCode.seconds}</p>
                                    }
                                </button>
                            }
                        />
                    }
                    <div className='w-full col-start-1 col-end-4 flex flex-col justify-start items-start '>
                        <h3 className='personalDetails__subtitle'>
                            Паспортные данные
                        </h3>
                        <div className='w-full grid grid-cols-2 gap-7 pt-5 pb-7'>
                            <div className='w-full flex flex-col justify-start items-start gap-4'>
                                <div className='w-full flex items-center gap-5'>
                                    <span className='text-gray-600 text-sm not-italic font-normal'>Лицевая сторона</span>
                                    {personalState.values.passport_frontCheck !== '' &&
                                        <button className='text-gray-900 text-sm not-italic font-bold hover:underline' onClick={() => {
                                            setPersonalState({
                                                ...personalState,
                                                values: {
                                                    ...personalState.values,
                                                    passport_front_side: new File([""], ""),
                                                    passport_frontCheck: ''
                                                }
                                            })}}
                                        >
                                        Изменить фото
                                    </button>
                                    }
                                </div>
                                <div className='w-full h-56 flex justify-center items-center p-2.5 bg-gray-200 rounded-xl'>
                                    {personalState.values.passport_frontCheck === ''
                                        ? <label htmlFor="frontImgUpload" className='passportUploadLabel'>
                                            <FileUploadIcon/>
                                            <p>Загрузить фотографию</p>
                                            <input id='frontImgUpload' type="file" accept='image/*' onChange={(event)=>{
                                                handlePassportChange(event, 'front')
                                            }} />
                                        </label>
                                        : <img className='h-full flex-shrink-0 object-cover' src={personalState.values.passport_frontCheck} alt="Лицевая сторона"/>
                                    }
                                </div>
                            </div>
                            <div className='w-full flex flex-col justify-start items-start gap-4'>
                                <div className='w-full flex items-center gap-5'>
                                    <span className='text-gray-600 text-sm not-italic font-normal'>Обратная сторона</span>
                                    {personalState.values.passport_backCheck !== '' &&
                                        <button className='text-gray-900 text-sm not-italic font-bold hover:underline' onClick={()=>{
                                            setPersonalState({
                                                ...personalState,
                                                values:{
                                                    ...personalState.values,
                                                    passport_back_side: new File([""], ""),
                                                    passport_backCheck: ''
                                                }
                                            })
                                        }}>
                                        Изменить фото
                                    </button>
                                    }
                                </div>
                                <div className='w-full h-56 flex justify-center items-center p-2.5 bg-gray-200 rounded-xl'>
                                    {personalState.values.passport_backCheck === ''
                                        ? <label htmlFor="backImgUpload" className='passportUploadLabel'>
                                            <FileUploadIcon/>
                                            <p>Загрузить фотографию</p>
                                            <input id='backImgUpload' type="file" accept='image/*' onChange={(event)=>{
                                                handlePassportChange(event, 'back')
                                            }}/>
                                        </label>
                                        : <img className='h-full flex-shrink-0 object-cover' src={personalState.values.passport_backCheck} alt="Обратная сторона"/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full col-start-1 col-end-4 flex justify-center items-center gap-7'>
                        <button type='button' className='cancel-button_orange h-12 w-full mt-4 w-min'
                                onClick={()=>{navigate(-1)}}
                        >
                            Отменить
                        </button>
                        <button disabled={personalState.validation.requestIsSent} type='submit' className='submit-button_orange h-12 w-full mt-4 w-min'>
                            {personalState.validation.requestIsSent
                                ?<div className="loader"></div>
                                :'Готово'
                            }
                        </button>

                    </div>
                </form>
            </div>
        </div>
    )
}