import React from "react";
import {createSearchParams, Navigate} from "react-router-dom";
import {getCookie} from 'typescript-cookie'
import {access_token_name, refresh_token_name} from "../http";
import {useTimer} from "react-timer-hook";

export function CreateCleanSearchParams(params: any){
    const newSearchObj = {...params}
    const keys = Object.keys(newSearchObj)
    for(let i=0;i < keys.length;i++){
        if(newSearchObj[keys[i]] === ""){
            delete newSearchObj[keys[i]]
        }
    }
    const emptyOrder = !Object.keys(newSearchObj).length;
    return emptyOrder ? '' : `?${createSearchParams(newSearchObj)}`
}
export function RemoveEmptyObj(params: any){
    const newSearchObj = {...params}
    const keys = Object.keys(newSearchObj)
    for(let i=0;i < keys.length;i++){
        if(newSearchObj[keys[i]] === ""){
            delete newSearchObj[keys[i]]
        }
    }
    return newSearchObj
}
export const RequireAuth = ({children}: {children: JSX.Element}) => {
    let access_token = getCookie(access_token_name)
    let refresh_token = getCookie(refresh_token_name)
    if (typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined') {
        return children
    }
    else{
        return <Navigate to="auth" replace />
    }
}
export const IfAuthed = ({children}: {children: JSX.Element}) => {
    let access_token = getCookie(access_token_name)
    let refresh_token = getCookie(refresh_token_name)

    if (typeof access_token !== 'undefined' && typeof refresh_token !== 'undefined') {
        return <Navigate to="/" replace />
    }
    else{
        return children
    }
}
export const ValidatePhoneNumber = (input_str: string) => {
    // Отфильтруем символы, оставим только цифры и знак "+"
    const filteredValue = input_str.replace(/[^0-9+]/g, '');

    // Если в начале ввода нет знака "+", добавляем его
    return filteredValue.startsWith('+') ? filteredValue : `+${filteredValue}`
}

export function InputCheckForNumbers(value: string){
    return value === '' || value < '0' ? '0' : value[0] === '0' ? value.slice(1) : value
}

export function CheckForPositiveNumbers(value: string){
    let valueReady = value.replace(/[^0-9]/g, ''); // Удаление всего, кроме цифр
    return valueReady.replace(/^0+/, '');
}

export function convertImageUrlToFile(url: any) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: 'image/jpeg' });
                const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
                resolve(file);
            } else {
                reject(new Error('Failed to convert image URL to file object.'));
            }
        };

        xhr.onerror = function () {
            reject(new Error('Failed to convert image URL to file object.'));
        };

        xhr.send();
    });
}

export const MyTimer = (expiryTimestamp: Date) => {
    const {
        seconds,
        restart,
    } = useTimer({expiryTimestamp});
    return ({seconds, restart});
}


