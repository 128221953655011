import React, {useEffect, useState} from "react";
import {
    CustomForm,
    CustomInput,
    CustomPageSizeInput,
    CustomTable,
    FilterSearchIcon,
    ShowAllStatusIcon, TableRowDeleteIcon, TableRowEditIcon,
} from "../../helpers/common";
import {Pagination} from "@mui/material";
import {CheckForPositiveNumbers, ValidatePhoneNumber} from "../../helpers/helpers";
import {OrdersService} from "../../services/OrdersService";
import {useNavigate} from "react-router-dom";
import {DeliveryService} from "../../services/DeliveryService";

const tableFilter = {
    search: '',
    start_date: '',
    status__status: '',
    end_date: '',
    page: 1,
    size: 20,
    total_pages: 1,
    updateTable: false,
}
const deliveryModalInitialState = {
    values: {
        address: '',
        phone: '',
        comment: '',
    },
    validation:{
        requestIsSent: false,
        modal_opened: false,
        type: '',
    }
}

export default function Orders() {
    const navigate = useNavigate()
    const [ordersTitle, setOrdersTitle] = useState('Все посылки')
    const [table, setTable] = useState<any>({
        selectedRows: [],
        filter: tableFilter,
        rows: [],
        loading: false,
        error: false,
        message: '',
        columns:
            [
                {field: 'id', headerName: 'ID', width: 120, hide: true},
                {field: 'tracking_number', headerName: 'Трек-номер', width: 150},
                {field: 'title', headerName: 'Наименование', width: 120},
                {
                    field: 'type_of_transport', headerName: 'Транспорт', width: 120, renderCell: (params: any) => (
                        <img src={params.type_of_transport.icon} alt="icon"/>
                    )
                },
                {
                    field: 'status', headerName: 'Статус', width: 120, renderCell: (params: any) => (
                        params.status && params.status.svg_script ? (
                            <div dangerouslySetInnerHTML={{ __html: params.status.svg_script }} />
                        ) : (
                            <div>No Status Available</div>
                        )
                    )
                },
                { field: 'actions', headerName: 'Действия', width: '80px', renderCell: (params: any) => {
                        return (
                            <div className="w-full flex justify-start items-center gap-5">
                                {params && params.status && params.status.status === 'inProcess' ? (
                                    <>
                                        <div className='tableRowIconButton' onClick={() => {
                                            navigate(`/orderEdit/${params.id}`);
                                        }}>
                                            <TableRowEditIcon />
                                        </div>

                                        <div className='tableRowIconButton' onClick={() => {
                                            OrdersService.DeleteOrder(params.id).then(() => {
                                                setTable({
                                                    ...table,
                                                    filter: {
                                                        ...table.filter,
                                                        updateTable: !table.filter.updateTable
                                                    }
                                                });
                                            });
                                        }}>
                                            <TableRowDeleteIcon />
                                        </div>
                                    </>
                                ) : (
                                    <span>No Actions Available</span>
                                )}
                            </div>
                            // <div className="w-full flex justify-start items-center gap-5">
                            // {params && params.status && params.status.status === 'inProcess' && (
                            //     <div className='tableRowIconButton' onClick={() => {
                            //         navigate(`/orderEdit/${params.id}`);
                            //     }}>
                            //         <TableRowEditIcon />
                            //     </div>
                            // )}

                            // {params && params.status && params.status.status === 'inProcess' && (
                            //     <div className='tableRowIconButton' onClick={() => {
                            //         OrdersService.DeleteOrder(params.id).then(() => {
                            //             setTable({
                            //                 ...table,
                            //                 filter: {
                            //                     ...table.filter,
                            //                     updateTable: !table.filter.updateTable
                            //                 }
                            //             });
                            //         });
                            //     }}>
                            //         <TableRowDeleteIcon />
                            //     </div>
                            // )}
                            // </div>
                        )
                    }},
            ]
    })
    const ordersList = OrdersService.GetOrders(table.filter)
    const getOrderStatuses = OrdersService.GetOrderStatusButtons()
    useEffect(() => {
        if (ordersList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                loading: true,
            }))
        } else if (ordersList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                error: true,
                message: ordersList.error?.message,
                loading: false,
            }))
        } else {
            const data = ordersList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                loading: false,
                error: false,
                message: '',
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                rows: data.results
            }))
        }
    }, [ordersList.loading, ordersList.error])


    const [deliveryModal, setDeliveryModal] = useState(deliveryModalInitialState)

    const submitDeliveryForm = ()=>{
        setDeliveryModal({
            ...deliveryModal,
            validation: {
                ...deliveryModal.validation,
                requestIsSent: true,
            },
        })
        DeliveryService.SendRequest(deliveryModal.values).then(()=>{
            setDeliveryModal(deliveryModalInitialState)
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Посылки</h1>
                <div className='flex items-center gap-7'>
                    <button className='cancel-button_orange h-10'
                            onClick={()=>{
                                setDeliveryModal({
                                    ...deliveryModal,
                                    validation: {
                                        ...deliveryModal.validation,
                                        modal_opened: true
                                    }
                                })
                            }}
                    >
                        Оформить доставку
                    </button>
                    <button className='submit-button_orange h-10'
                            onClick={()=>{navigate(`/orderAdd`)}}
                    >
                        Добавить посылку
                    </button>
                </div>
            </div>

            <div className='home-page__statuses mb-20'>
                {getOrderStatuses.loading
                    ?
                    <>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                        <div className={`status-button`}>
                            <div>
                                <p></p>
                                <span>
                                </span>
                            </div>
                        </div>
                    </>
                    : !getOrderStatuses.error &&
                    <>
                        <div
                            className={`status-button ${table.filter.status__status === '' ? 'status-button_selected' : ''}`}
                            onClick={() => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        status__status: '',
                                        page: 1,
                                    }
                                })
                                setOrdersTitle('Все посылки')
                            }}>
                            <div dangerouslySetInnerHTML={{__html:
                                    '<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.54 86.54"><defs><style>.cls-1:{fill:#f15a24;}</style></defs><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><polygon class="cls-1" points="63.77 80.85 63.77 86.54 0 86.54 0 22.77 5.69 22.77 5.69 80.85 63.77 80.85"/><path class="cls-1" d="M17.08,69.46V11.4H11.4V75.15H75.15V69.46Z"/><path class="cls-1" d="M22.77,0V63.77H86.54V0Zm20,50.94V12.83L77.32,31.89Z"/></g></g></svg>'
                            }}/>
                            <div>
                                <p>Все посылки</p>
                                <span>
                                    {getOrderStatuses.result?.data.map((result: any) => result.count_of_orders).reduce((partialSum: any, a: any) => partialSum + a, 0)}
                                </span>
                            </div>
                        </div>
                        {getOrderStatuses.result?.data.map((item: any) => (
                            <div
                                className={`status-button ${table.filter.status__status === item.status ? 'status-button_selected' : ''}`}
                                id={item.id}
                                key={item.id}
                                onClick={() => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            status__status: item.status,
                                            page: 1,
                                        }
                                    })
                                    setOrdersTitle(item.name)
                                }}>
                                <div dangerouslySetInnerHTML={{__html: item.svg_script}}/>
                                <div>
                                    <p>{item.name}</p>
                                    <span>{item.count_of_orders}</span>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>

            <div className='flex w-full flex-row justify-start items-center gap-7 mb-8'>
                <h1 className='status__title'>{ordersTitle}</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-7'>
                <div className='flex w-fit flex-row justify-start items-center gap-5'>
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Начало даты'
                        value={table.filter.start_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    start_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='filter-input'
                        type='date'
                        placeholder='Конец даты'
                        value={table.filter.end_date}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    end_date: event.target.value,
                                    page: 1,
                                }
                            })
                        }}
                    />
                </div>
                <CustomInput
                    className='filter-input_search'
                    type='text'
                    placeholder='Поиск'
                    value={table.filter.search}
                    onChange={(event) => {
                        setTable({
                            ...table,
                            selectedRows:[],
                            filter: {
                                ...table.filter,
                                search: event.target.value,
                                page: 1,
                            }
                        })
                    }}
                    inputProps={
                        <div className='text-input_icon_button'>
                            <FilterSearchIcon/>
                        </div>
                    }
                />
            </div>

            <div className='flex w-full flex-row justify-start items-start gap-5 mb-10'>
                <CustomTable
                    columns={table.columns}
                    rows={table.rows}
                    checkboxSelection={false}
                    loading={table.loading}
                    error={table.error}
                    message={table.message}
                    onRowDoubleClick={(params)=>navigate(`/orderDetails/${params.id}`)}
                    footer={
                        <div className="bg-white flex justify-between items-center p-2.5">
                            <div className='flex justify-start items-center gap-4'>
                                <Pagination
                                    count={table.filter.total_pages}
                                    page={table.filter.page}
                                    onChange={(event, value) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: value,
                                            }
                                        })
                                    }}
                                />
                                <CustomPageSizeInput
                                    value={table.filter.size}
                                    onChange={(e) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                page: 1,
                                                size: CheckForPositiveNumbers(e.target.value),
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    }
                />
            </div>

            <CustomForm
                open={deliveryModal.validation.modal_opened}
                title={'Оформить доставку'}
                style={{maxWidth: '844px', width:'100%'}}
                buttonText={'Готово'}
                cancelButton={true}
                cancelButtonText='Отмена'
                onClose={()=>{
                    setDeliveryModal(deliveryModalInitialState)
                }}
                onSubmit={()=>{
                    submitDeliveryForm()
                }}
                disabled={deliveryModal.validation.requestIsSent}
                requestIsSent={deliveryModal.validation.requestIsSent}
                children={
                    <div className=' w-full grid grid-cols-2 gap-x-5 gap-y-5'>
                        <CustomInput
                            className='text-input text-input_with-border'
                            type='text'
                            placeholder={'Адрес'}
                            required={true}
                            label={'Адрес'}
                            value={deliveryModal.values.address}
                            onChange={(event)=>{
                                setDeliveryModal({
                                    ...deliveryModal,
                                    values:{
                                        ...deliveryModal.values,
                                        address: event.target.value,
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            className='text-input text-input_with-border'
                            type='text'
                            placeholder={'Телефон'}
                            required={true}
                            label={'Телефон'}
                            value={deliveryModal.values.phone}
                            onChange={(event)=>{
                                setDeliveryModal({
                                    ...deliveryModal,
                                    values:{
                                        ...deliveryModal.values,
                                        phone: ValidatePhoneNumber(event.target.value),
                                    }
                                })
                            }}
                        />
                        <CustomInput
                            className='text-input text-input_with-border col-start-1 col-end-3'
                            type='text'
                            placeholder={'Комментарий'}
                            required={false}
                            label={'Комментарий'}
                            value={deliveryModal.values.comment}
                            onChange={(event)=>{
                                setDeliveryModal({
                                    ...deliveryModal,
                                    values:{
                                        ...deliveryModal.values,
                                        comment: event.target.value,
                                    }
                                })
                            }}
                        />
                    </div>
                }
            />
        </div>
    )
}