import React from "react";
import {OrdersService} from "../../services/OrdersService";
import {Link} from "react-router-dom";
import {NoDataIcon, ShowAllMobileStatusIcon} from "../../helpers/common";

export default function HomeM() {
    const getOrderStatuses = OrdersService.GetOrderStatusButtons()

    return (
        <div className='defaultWrapper'>
            <div className='w-full mb-5'>
                <h3 className='text-gray-900 text-2xl not-italic font-bold'>Статусы</h3>
            </div>
            <div className='w-full grid grid-cols-2 gap-3'>
                {getOrderStatuses.loading
                    ?
                    <div className='col-start-1 col-end-3 w-full flex  flex-col justify-center items-center py-32 gap-5'>
                        <div className='loader'></div>
                        <p className='text-gray-500 text-xs not-italic font-medium'>
                            Загрузка
                        </p>
                    </div>
                    : getOrderStatuses.error
                        ?
                        <div className='col-start-1 col-end-3 w-full flex flex-col justify-center items-center py-32 gap-5'>
                            <NoDataIcon/>
                            <p className='text-gray-500 text-xs not-italic font-medium'>
                                {getOrderStatuses.error.message}
                            </p>
                        </div>
                        :
                        <>
                            <Link to={`/orders/showAll`} className='mobileStatusButton rounded-xl bg-white p-3 w-full flex justify-start items-center gap-2'>
                                <div dangerouslySetInnerHTML={{__html:
                                        '<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.54 86.54"><defs><style>.cls-1:{fill:#f15a24;}</style></defs><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><polygon class="cls-1" points="63.77 80.85 63.77 86.54 0 86.54 0 22.77 5.69 22.77 5.69 80.85 63.77 80.85"/><path class="cls-1" d="M17.08,69.46V11.4H11.4V75.15H75.15V69.46Z"/><path class="cls-1" d="M22.77,0V63.77H86.54V0Zm20,50.94V12.83L77.32,31.89Z"/></g></g></svg>'
                                }}/>
                                <div className='flex flex-col justify-center items-start'>
                                    <p className='text-gray-900 text-sm not-italic font-medium'>
                                        Все посылки
                                    </p>
                                    <span className='text-red-700 text-xs not-italic font-normal'>
                                        {getOrderStatuses.result?.data.map((result: any) => result.count_of_orders).reduce((partialSum: any, a: any) => partialSum + a, 0)}
                                    </span>
                                </div>
                            </Link>
                            {getOrderStatuses.result?.data.map((status: any, index: number)=>
                                <Link to={`/orders/${status.status}`} key={index} className='mobileStatusButton rounded-xl bg-white p-3 w-full flex justify-start items-center gap-2'>
                                    <div dangerouslySetInnerHTML={{__html: status.svg_script}}/>
                                    <div className='flex flex-col justify-center items-start'>
                                        <p className='text-gray-900 text-sm not-italic font-medium whitespace-nowrap'>
                                            {status.name}
                                        </p>
                                        <span className='text-red-700 text-xs not-italic font-normal'>
                                            {status.count_of_orders}
                                        </span>
                                    </div>
                                </Link>
                            )}
                        </>

                }
            </div>
        </div>
    )
}