import {$axios} from "../http";
import {useAsync} from "react-async-hook";

export const PersonalService = {
    GetPersonalInfo() {
        return useAsync(async () => {
            return await $axios.get('/personal/info/')
        }, [])
    },
    async ChangePassword (data: any){
        await $axios.post('/password/change/', data)
    },
    async UpdatePersonalInfo(data: any) {
        let form_data = new FormData()
        if (data.passport_back_side.name === '') {
            delete data.passport_back_side
        }
        if (data.passport_front_side.name === '') {
            delete data.passport_front_side
        }
        for (let key in data) {
            form_data.append(key, data[key]);
        }
        return await $axios.put('/personal/info/update/', form_data)
    },
}