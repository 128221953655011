import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import {IfAuthed, RequireAuth} from "./helpers/helpers";
import {useMediaQuery} from "usehooks-ts";
import Auth from "./pages/desktop/auth/Auth";
import Orders from "./pages/desktop/Orders";
import OrderDetails from "./pages/desktop/OrderDetails";
import OrderEdit from "./pages/desktop/OrderEdit";
import OrderAdd from "./pages/desktop/OrderAdd";
import Personal from "./pages/desktop/Personal";
import PersonalEdit from "./pages/desktop/PersonalEdit";
import Addresses from "./pages/desktop/Addresses";
import Notifications from "./pages/desktop/Notifications";
import Contacts from "./pages/desktop/Contacts";
//
import LayoutM from "./components/LayoutM";
import AuthM from "./pages/mobile/auth/Auth";
import HomeM from "./pages/mobile/HomeM";
import OrdersM from "./pages/mobile/OrdersM";
import AddressesM from "./pages/mobile/AddressesM";
import OrderDetailsM from "./pages/mobile/OrderDetailsM";
import PersonalM from "./pages/mobile/PersonalM";
import PersonalEditM from "./pages/mobile/PersonalEditM";
import ContactsM from "./pages/mobile/ContactsM";
import NotificationsM from "./pages/mobile/NotificationsM";


function App() {
    const isMobileDevice = useMediaQuery("(max-width: 720px)");
    const isDesktopDevice = useMediaQuery("(min-width: 721px)");

  return (
      <BrowserRouter>
          {isDesktopDevice &&
              <Routes>
                  <Route path='/*' element={
                      <RequireAuth>
                          <Layout/>
                      </RequireAuth>
                  }>
                      <Route index
                             element={<Orders/>}
                      />
                      <Route path='orderDetails/:id'
                             element={<OrderDetails/>}
                      />
                      <Route path='orderEdit/:id'
                             element={<OrderEdit/>}
                      />
                      <Route path='orderAdd'
                             element={<OrderAdd/>}
                      />
                      <Route path='personal'
                             element={<Personal/>}
                      />
                      <Route path='personalEdit'
                             element={<PersonalEdit/>}
                      />
                      <Route path='addresses'
                             element={<Addresses/>}
                      />
                      <Route path='notifications'
                             element={<Notifications/>}
                      />
                      <Route path='contacts'
                             element={<Contacts/>}
                      />

                  </Route>

                  <Route path='auth' element={
                      <IfAuthed>
                          <Auth/>
                      </IfAuthed>
                  }/>
              </Routes>
          }
          {isMobileDevice &&
              <Routes>
                  <Route path='/*' element={
                      <RequireAuth>
                          <LayoutM/>
                      </RequireAuth>
                  }>
                      <Route index
                             element={<HomeM/>}
                      />
                      <Route path='orders/:status'
                             element={<OrdersM/>}
                      />
                      <Route path='ordersDetail/:id'
                             element={<OrderDetailsM/>}
                      />
                      <Route path='addresses'
                             element={<AddressesM/>}
                      />
                      <Route path='personal'
                             element={<PersonalM/>}
                      />
                      <Route path='personalEdit'
                             element={<PersonalEditM/>}
                      />
                      <Route path='contacts'
                             element={<ContactsM/>}
                      />
                      <Route path='notifications'
                             element={<NotificationsM/>}
                      />

                  </Route>

                  <Route path='auth' element={
                      <IfAuthed>
                          <AuthM/>
                      </IfAuthed>
                  }/>
              </Routes>
          }

      </BrowserRouter>
  );
}

export default App;
