import React, {useEffect, useState} from "react";
import {
    CustomInput, CustomSelect,
    RollBackIcon,
} from "../../helpers/common";
import {useNavigate, useParams} from "react-router-dom";
import {CountriesService} from "../../services/CountriesService";
import {TransportsService} from "../../services/TransportsService";
import {OrdersService} from "../../services/OrdersService";
import {CheckForPositiveNumbers} from "../../helpers/helpers";
import {OrderI} from "../../models/OrderI";

const orderStateInitialValues = {
    values:{
        tracking_number: '',
        title: '',
        quantity: '',
        sending_country: '',
        type_of_transport: '',
    },
    validation:{
        tracking_numberCheck: false,
        tracking_numberHelperText: '',
        requestIsSent: false,
    }
}
export default function OrderEdit() {
    const {id} = useParams()

    const navigate = useNavigate();

    const [orderState, setOrderState] = useState<any>(orderStateInitialValues)

    const sending_countriesList = CountriesService.GetSendingCountriesList()
    const transports = TransportsService.GetTransportsList()

    const handleSubmit = (event: React.FormEvent) =>{
        event.preventDefault()
        setOrderState({
            ...orderState,
            validation: {
                ...orderState.validation,
                requestIsSent: true,
            }
        })
        OrdersService.UpdateOrder(orderState.values, id).then(()=>{
            navigate(-1)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const order = OrdersService.GetOrder(id)

    useEffect(()=>{
        if(!order.loading && !order.error && typeof id !== 'undefined'){
            setOrderState((prevState: any)=>({
                ...prevState,
                values: {
                    ...prevState.values,
                    ...order.result?.data,
                    type_of_transport: order.result?.data.type_of_transport.id,
                    sending_country: order.result?.data.sending_country.id,
                }
            }))
        }
    }, [order.loading, order.error])
    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Редактировать посылку</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-2'>
                    <div className='page__rollBack-icon' onClick={()=>{navigate(-1)}}>
                        <div>
                            <RollBackIcon/>
                        </div>
                        <p>Назад</p>
                    </div>
                </div>
            </div>

            <div className='flex w-full flex-col justify-start items-center bg-white rounded-xl p-8 gap-7 mb-12'>
                <form onSubmit={handleSubmit} className='w-full grid grid-cols-3 gap-7'>
                    <CustomInput
                        className='text-input text-input_with-border'
                        type='text'
                        placeholder={'Трек-номер'}
                        required={true}
                        label={'Трек-номер'}
                        value={orderState.values.tracking_number}
                        onChange={(event)=>{
                            setOrderState({
                                ...orderState,
                                values:{
                                    ...orderState.values,
                                    tracking_number: event.target.value,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='text-input text-input_with-border'
                        type='text'
                        placeholder={'Наименование'}
                        required={true}
                        label={'Наименование'}
                        value={orderState.values.title}
                        onChange={(event)=>{
                            setOrderState({
                                ...orderState,
                                values:{
                                    ...orderState.values,
                                    title: event.target.value,
                                }
                            })
                        }}
                    />
                    <CustomInput
                        className='text-input text-input_with-border'
                        type='number'
                        placeholder={'Количество'}
                        required={true}
                        label={'Количество'}
                        value={orderState.values.quantity}
                        onChange={(event)=>{
                            setOrderState({
                                ...orderState,
                                values:{
                                    ...orderState.values,
                                    quantity: CheckForPositiveNumbers(event.target.value),
                                }
                            })
                        }}
                    />
                    <CustomSelect
                        className='default-select'
                        placeholder='Страна'
                        label='Страна'
                        required={true}
                        value={orderState.values.sending_country}
                        onChange={(event)=>{
                            setOrderState({
                                ...orderState,
                                values:{
                                    ...orderState.values,
                                    sending_country: event.target.value,
                                }
                            })
                        }}
                    >
                        <option value=""></option>
                        {!sending_countriesList.loading && !sending_countriesList.error && sending_countriesList.result?.data.map((country: any, i:number)=>(
                            <option key={i} value={country.id}>{country.name}</option>
                        ))}
                    </CustomSelect>
                    <CustomSelect
                        className='default-select'
                        placeholder='Тип транспорта'
                        label='Тип транспорта'
                        required={true}
                        value={orderState.values.type_of_transport}
                        onChange={(event)=>{
                            setOrderState({
                                ...orderState,
                                values:{
                                    ...orderState.values,
                                    type_of_transport: event.target.value,
                                }
                            })
                        }}
                    >
                        <option value=""></option>
                        {!transports.loading && !transports.error && transports.result?.data.map((transport: any, i:number)=>(
                            <option key={i} value={transport.id}>{transport.name}</option>
                        ))}
                    </CustomSelect>
                    <div className='col-start-1 col-end-4 w-full flex gap-7 justify-center items-center'>
                        <button type='button' className='cancel-button_orange h-10'
                                onClick={()=>{navigate(-1)}}
                        >
                            Отменить
                        </button>
                        <button disabled={orderState.validation.requestIsSent} type='submit' className='submit-button_orange h-10'>
                            {orderState.validation.requestIsSent
                                ?<div className="loader"></div>
                                :'Редактировать'
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}