import React from "react";
import {
    NoDataIcon,
    RollBackIcon,
} from "../../helpers/common";
import {useNavigate, useParams} from "react-router-dom";
import {OrdersService} from "../../services/OrdersService";
import moment from "moment/moment";

export default function OrderDetailsM() {
    const {id} = useParams()

    const groupInfo = OrdersService.GetOrder(id)

    return(
        <div className='defaultWrapper'>
            <div className='w-full mb-5'>
                <h3 className='text-gray-900 text-2xl not-italic font-bold'>Детальный просмотр посылки</h3>
            </div>

            {groupInfo.loading
                ?
                <div className='col-start-1 col-end-3 w-full flex  flex-col justify-center items-center py-32 gap-5'>
                    <div className='loader'></div>
                    <p className='text-gray-500 text-xs not-italic font-medium'>
                        Загрузка
                    </p>
                </div>
                : groupInfo.error
                    ?
                    <div className='col-start-1 col-end-3 w-full flex flex-col justify-center items-center py-32 gap-5'>
                        <NoDataIcon/>
                        <p className='text-gray-500 text-xs not-italic font-medium'>
                            {groupInfo.error.message}
                        </p>
                    </div>
                    :
                    <div className='w-full flex flex-col gap-5'>
                        <div className='bg-white w-full p-6 rounded-xl flex flex-col justify-start items-start gap-5'>
                            <h1 className='text-gray-900 text-sm not-italic font-bold'>
                                Информация получателя
                            </h1>
                            <div className='w-full grid grid-cols-2 gap-3'>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Получатель:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.user.full_name}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Код клиента:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.user.code}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Номер:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                       {groupInfo.result?.data.user.phone}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Адрес:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.user.address}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='bg-white w-full p-6 rounded-xl flex flex-col justify-start items-start gap-5'>
                            <h1 className='text-gray-900 text-sm not-italic font-bold'>
                                Информация посылки
                            </h1>
                            <div className='w-full grid grid-cols-2 gap-3'>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Наименование:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.title}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Трек-номер:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.tracking_number}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Количество:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                       {groupInfo.result?.data.quantity}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Цена:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.price}
                                    </span>
                                </div>


                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Статус:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.status.name}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Транспорт:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.type_of_transport.name}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Страна:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                       {groupInfo.result?.data.sending_country.name}
                                    </span>
                                </div>
                                <div className='w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Дата создания:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {moment(groupInfo.result?.data.created_at).format('YYYY-MM-DD')}
                                    </span>
                                </div>
                                <div className='col-start-1 col-end-3 w-full flex flex-col justify-start items-start gap-2'>
                                    <p className='text-yellow-400 text-xs not-italic font-normal'>
                                        Комментарий:
                                    </p>
                                    <span className='text-gray-900 text-xs not-italic font-normal'>
                                        {groupInfo.result?.data.comment}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}