
const defaultState = {
    user:{
        id: '',
        code: '',
        full_name: 'user',
        phone: '',
        city: '',
        address: '',
        region: '',
        email: '',
        passport_front_side: '',
        passport_back_side: '',
    }
}

export const personalReducer = (state = defaultState, action: any) =>{
    switch (action.type){
        case "UPDATE_DATA":
            return {
                ...state, user:{...state.user, ...action.payload}
            }
        default: return state
    }
}