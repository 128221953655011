import React from "react";
import {
    RollBackIcon,
} from "../../helpers/common";
import {useNavigate, useParams} from "react-router-dom";
import {OrdersService} from "../../services/OrdersService";

export default function OrderDetails() {
    const {id} = useParams()
    const navigate = useNavigate();

    const groupInfo = OrdersService.GetOrder(id)

    return(
        <div className='componentMainWrapper'>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <h1 className='page__title'>Детальный просмотр посылки</h1>
            </div>

            <div className='flex w-full flex-row justify-between items-center gap-7 mb-12'>
                <div className='flex w-fit flex-row justify-start items-center gap-2'>
                    <div className='page__rollBack-icon' onClick={()=>{navigate(-1)}}>
                        <div>
                            <RollBackIcon/>
                        </div>
                        <p>Назад</p>
                    </div>
                </div>
            </div>


            <div className='flex w-full flex-col justify-start items-start bg-white rounded-xl py-8 mb-12'>
                {!groupInfo.loading && !groupInfo.error
                    ?
                    <div className='flex w-full flex flex-col justify-start items-start'>
                        <h1 className="orderDetails__title w-full px-8">
                            Данные посылки №{groupInfo.result?.data.id}
                        </h1>
                        <h3 className="orderDetails__subtitle w-full px-8">
                            Информация получателя
                        </h3>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader h-8 items-center bg-tableHeaderColor w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>Получатель</p></div>
                                <div><p>Код клиента</p></div>
                                <div><p>Номер</p></div>
                                <div><p>Адрес</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>{groupInfo.result?.data.user?.full_name}</p></div>
                                <div><p>{groupInfo.result?.data.user?.code}</p></div>
                                <div><p>{groupInfo.result?.data.user?.phone}</p></div>
                                <div><p>{groupInfo.result?.data.user?.address}</p></div>
                            </div>
                        </div>

                        <h3 className="orderDetails__subtitle w-full px-8">
                            Информация поcылки
                        </h3>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader py-2 items-center bg-tableHeaderColor w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>Наименование</p></div>
                                <div><p>Трек-номер</p></div>
                                <div><p>Количество</p></div>
                                <div><p>Страна</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>{groupInfo.result?.data.title}</p></div>
                                <div><p>{groupInfo.result?.data.tracking_number}</p></div>
                                <div><p>{groupInfo.result?.data.quantity}</p></div>
                                <div><p>{groupInfo.result?.data.sending_country.name}</p></div>
                            </div>
                        </div>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader py-2 items-center bg-tableHeaderColor w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>Дата поступления на склад</p></div>
                                <div><p>Дата сортировки</p></div>
                                <div><p>Дата выдачи</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>01.01.2023</p></div>
                                <div><p>01.01.2023</p></div>
                                <div><p>01.01.2023</p></div>
                            </div>
                        </div>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader py-2 items-center bg-tableHeaderColor w-full px-8'>
                                <div><p>Комментарий</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8'>
                                <div>
                                    <p>
                                        {groupInfo.result?.data.comment}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex w-full flex flex-col justify-start items-start'>
                        <h1 className="orderDetails__title w-full px-8">
                            Данные посылки №...
                        </h1>
                        <h3 className="orderDetails__subtitle w-full px-8">
                            Информация получателя
                        </h3>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader h-8 items-center bg-tableHeaderColor w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>Получатель</p></div>
                                <div><p>Код клиента</p></div>
                                <div><p>Номер</p></div>
                                <div><p>Адрес</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                            </div>
                        </div>

                        <h3 className="orderDetails__subtitle w-full px-8">
                            Информация поcылки
                        </h3>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader py-2 items-center bg-tableHeaderColor w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>Наименование</p></div>
                                <div><p>Трек-номер</p></div>
                                <div><p>Количество</p></div>
                                <div><p>Страна</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                            </div>
                        </div>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader py-2 items-center bg-tableHeaderColor w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>Дата поступления на склад</p></div>
                                <div><p>Дата сортировки</p></div>
                                <div><p>Дата выдачи</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8 grid grid-cols-4 gap-x-6'>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                                <div><p>...</p></div>
                            </div>
                        </div>
                        <div className='w-full flex flex-col justify-start items-start gap-4 mt-5'>
                            <div className='orderDetails__textHeader py-2 items-center bg-tableHeaderColor w-full px-8'>
                                <div><p>Комментарий</p></div>
                            </div>
                            <div className='orderDetails__textBody items-center w-full px-8'>
                                <div>
                                    <p>
                                        ...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}